<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar auditado', v-model='search', hide-details)
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Auditado ativos' : 'Auditado inativos'", v-model="filter.ativo")
            v-btn(color="bt-primary", @click="openDialogAuditado()").white--text Cadastrar
          v-divider
          v-card-text.pa-1
            v-data-table(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading").elevation-0
              template(slot='items', slot-scope='props')
                td(width="5%").text-xs-center {{ props.item.id }}
                td.text-xs-left {{ props.item.nome }}
                td.text-xs-left {{ props.item.identificador }}
                td.text-xs-center {{ props.item.nome_responsavel ? props.item.nome_responsavel : ' -- ' }}
                td.text-xs-center {{ props.item.email_responsavel ? props.item.email_responsavel : ' -- ' }}
                td.text-xs-center {{ props.item.telefone_responsavel ? props.item.telefone_responsavel : ' -- ' }}
                td(width="15%").text-xs-center
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='success', small, :to="{ name: 'AuditadoDetalhes', params: { id: props.item.id } }")
                      v-icon visibility
                    span Visualizar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}

  v-dialog(v-model="dialogAuditado", width="auto", scrollable, max-width="900px", persistent, v-if="dialogAuditado")
      v-card
        form(@submit.prevent="salvar('auditado')", data-vv-name="auditado")
          v-card-title.headline.grey.lighten-2
            v-layout(row, wrap)
              v-flex.md11
                span.headline Cadastro de auditado
              v-flex.md1.text-xs-right
                v-tooltip(top)
                  v-btn(icon, slot="activator", @click.native="$validator.pause(); dialogAuditado = false; auditadoAux = {}").pa-0.mx-0.my-0.ma-0
                    v-icon close
                  span Fechar
          v-divider
          v-card-text
            v-layout(row, wrap)
              v-flex.md6.xs12.pa-1
                v-text-field(label="Auditado", color="gray", required, v-validate="'required'", :error-messages="errors.collect('nome')", data-vv-name="nome", data-vv-as="nome", type="text", v-model='auditadoAux.nome')
              v-flex.md6.xs12.pa-1
                v-autocomplete(label="Selecione o tipo de identificador", :items="listTiposIdentificadores", :disabled="!listTiposIdentificadores.length", item-text="nome", item-value="id", color="gray", v-model="auditadoAux.tipo_identificador_id", :loading="loadingTiposIdentificadores", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
            v-layout(row, wrap)
              v-flex.md6.xs12.pa-1
                v-text-field(label="Identificador", color="gray", required, v-validate="'required'", :error-messages="errors.collect('identificador')", data-vv-name="identificador", data-vv-as="identificador", type="text", v-model='auditadoAux.identificador')
              v-flex.md6.xs12.pa-1
                v-text-field(label="Gestor", color="gray", :error-messages="errors.collect('nome_responsavel')", data-vv-name="nome_responsavel", data-vv-as="nome_responsavel", type="text", v-model='auditadoAux.nome_responsavel')
            v-layout(row, wrap)
              v-flex.md6.xs12.pa-1
                v-text-field(label="Email do gestor", color="gray", :error-messages="errors.collect('email_responsavel')", data-vv-name="email_responsavel", data-vv-as="email_responsavel", type="text", v-model='auditadoAux.email_responsavel')
              v-flex.md6.xs12.pa-1
                v-text-field(label="Contato do gestor", color="gray", :error-messages="errors.collect('telefone_responsavel')", data-vv-name="telefone_responsavel", data-vv-as="telefone_responsavel", type="text", v-model='auditadoAux.telefone_responsavel')
            v-layout(row, wrap)
              v-flex.xs6.pa-1
                v-textarea(color="primary", label='Descrição', type="text", v-model='auditadoAux.descricao')
          v-divider
          v-card-actions.headline.grey.lighten-2
            v-layout(row, wrap).pa-1
              v-flex.xs12.text-xs-right
                v-btn(color="black", flat, @click.native="dialogAuditado = false; auditadoAux = {}") Fechar
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from "lodash";
import AccessLock from "@/components/AccessLock";

export default {
    components: {
        AccessLock,
    },
    data: () => ({
        filter: { ativo: 1 },
        auditados: [],
        dialogAuditado: false,
        auditadoAux: {},
        listTiposIdentificadores: [],
        loadingTiposIdentificadores: false,
        headers: [
            { text: "#", align: "center", value: "id" },
            { text: "Auditado", align: "left", value: "nome" },
            { text: "Identificador", align: "left", value: "nome" },
            { text: "Gestor", align: "center", value: "nome_responsavel" },
            {
                text: "Email do gestor",
                align: "center",
                value: "email_responsavel",
            },
            {
                text: "Contato do gestor",
                align: "center",
                value: "teleofne_responsavel",
            },
            { text: "Ações", align: "center", sortable: false },
        ],
        search: "",
        loading: false,
    }),
    computed: {
        list() {
            return _.filter(this.auditados, { ativo: this.filter.ativo });
        },
    },
    mounted() {
        this.getAuditados();
    },
    methods: {
        async getAuditados() {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.get("/auditado");
                vm.auditados = response.data.rows;
                vm.loading = false;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async changeAtivo(auditado) {
            const vm = this;
            try {
                const obj = _.cloneDeep(auditado);
                obj.ativo = obj.ativo ? 0 : 1;
                const response = await vm.$axios.put(
                    `/auditado/${obj.id}`,
                    obj,
                );
                auditado.ativo = response.data.ativo;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: `Auditado ${auditado.ativo ? "ativado" : "desativado"} com sucesso!`,
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
            }
        },
        async save(auditado) {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.post("/auditado", auditado);
                if (response && response.data && response.data.id) {
                    vm.auditados.push(response.data);
                    vm.dialogAuditado = false;
                    vm.auditadoAux = {};
                } else {
                    vm.dialogAuditado = false;
                    vm.auditadoAux = {};
                    throw false;
                }
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Auditado salvo com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao salvar o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async update(auditado) {
            const vm = this;
            try {
                const response = await vm.$axios.put(
                    `/auditado/${auditado.id}`,
                    auditado,
                );
                vm.loading = false;
                window.getApp.$emit("APP_ALERT", {
                    color: "success",
                    text: "Auditado salvo com sucesso!",
                });
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao atualizar o auditado. Tente novamente!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async salvar(scope) {
            const vm = this;
            try {
                vm.loading = true;
                const result = await vm.$validator.validateAll();
                if (!result) throw "Preencha todos os campos corretamente!";
                vm[vm.auditadoAux.id ? "update" : "save"](vm.auditadoAux);
                vm.$validator.reset();
            } catch (error) {
                window.getApp.$emit("APP_ALERT", { color: "red", text: error });
                vm.loading = false;
            }
        },
        async getTiposIdentificadores() {
            const vm = this;
            try {
                vm.loading = true;
                const response = await vm.$axios.get("/tipo-identificador", {
                    params: { ativo: 1 },
                });
                vm.listTiposIdentificadores = response.data.rows;
                vm.loading = false;
            } catch (error) {
                let err =
                    error.response && error.response.data
                        ? error.response.data.error
                        : "Ocorreu um erro ao buscar os dados. Atualize a página!";
                window.getApp.$emit("APP_ALERT", { color: "red", text: err });
                vm.loading = false;
            }
        },
        async openDialogAuditado() {
            const vm = this;
            vm.dialogAuditado = true;
            vm.getTiposIdentificadores();
        },
        customFilter(item, queryText, itemText) {
            const hasValue = (val) => (val != null ? val : "");
            const text = hasValue(item.nome);
            const query = hasValue(queryText);
            return (
                text
                    .toString()
                    .toLowerCase()
                    .indexOf(query.toString().toLowerCase()) > -1
            );
        },
    },
};
</script>

<style scoped></style>
